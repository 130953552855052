import { promisefy } from '@/utils/req';
export default <any>{
  getRmbg(data = {}) {
    return promisefy(data, '/ajax/rmbg', 'post');
  },
};
//接口返回的数据字段类型
export type RmbgType = {
  path: string;
};
