<script lang="ts">
export default {
  meta: {
    path: '/rmbg',
    title: '背景消除',
    enTitle: 'remove bg',
    auth: false,
    cache: false,
  },
  name: 'rmbg',
};
</script>
<script lang="ts" setup>
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import Api from '@/apis/rmbg';
import type { UploadProps } from 'element-plus';

const loading = ref(false);
const resultList = ref([]);
const dialogVisible = ref(false);
const dialogImageUrl = ref('');
const uploadUrl = `${import.meta.env.VITE_APP_API_HOST}ajax/upload`;
function beforeUpload(file: File) {
  // console.warn('beforeUpload', file);
  const limitSize = file.size / 1024 / 1024 < 50; // 最大上传100M
  if (!limitSize) {
    ElMessage.error('不得上传超过50M的文件');
    return false;
  }
  if (loading.value) {
    ElMessage.error('请稍后，系统繁忙');
    return false;
  }
  return true;
}
const handlePreview: UploadProps['onPreview'] = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url || '';
  dialogVisible.value = true;
};
const handleSuccess = async (res: any, file: File) => {
  console.log('handleSuccess', res, file);
  if (res.rcode !== 200) {
    ElMessage.error('上传失败', res.msg);
    return;
  } else {
    loading.value = true;
    Api.getRmbg({ key: res.data.key })
      .then((result: any) => {
        console.log('result', result);
        resultList.value.push(
          `${import.meta.env.VITE_APP_API_HOST}static/${result}`
        );
      })
      .finally(() => {
        loading.value = false;
      });
  }
};
const handleFailure = (error: any) => {
  console.log('error', error.status);
  ElMessage.error('上传失败,status:' + error.status);
};
const downloadImage = (item: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', item, true);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const blob = xhr.response;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob); // blob 是要下载的文件数据
    link.download = 'image.png'; // 设置下载文件名
    link.click();
    // 使用 blob 创建 a 标签或 iframe 进行下载
  };
  xhr.send();
};
</script>

<template>
  <ck-page footer header>
    <el-upload
      class="upload-btn"
      :action="uploadUrl"
      :on-preview="handlePreview"
      list-type="picture-card"
      accept="image/png, image/jpeg, image/jpg"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handleFailure"
    >
      <el-button round type="primary">上传图片</el-button>
      <template #tip>
        <div class="el-upload__tip">
          一次只能上传一个文件，且不超过50MB，背景太复杂的可能效果不佳
        </div>
      </template>
    </el-upload>
    <Teleport to="body">
      <el-dialog width="90%" v-model="dialogVisible">
        <img class="w-full block" :src="dialogImageUrl" alt="Preview Image" />
      </el-dialog>
    </Teleport>
    <main class="pt-4">
      <h3>转换结果</h3>
      <div v-if="loading" class="flex">Loading...</div>
      <div class="flex flex-wrap gap-4">
        <div
          v-for="(item, index) in resultList"
          :key="item"
          class="max-w-[300px]"
        >
          <el-image
            :initial-index="index"
            :preview-src-list="resultList"
            class="block w-full"
            :src="item"
            alt=""
          />
          <el-button type="success" round @click="() => downloadImage(item)"
            >下载</el-button
          >
        </div>
      </div>
    </main>
  </ck-page>
</template>

<style lang="scss"></style>
